import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import DELETE_SYSTEM_MUTATION from '@/graphql/mutations/deleteSystem.gql';
import DELETE_COMPONENT_MUTATION from '@/graphql/mutations/deleteComponent.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import UPDATE_COMPONENT_MUTATION from '@/graphql/mutations/updateComponent.gql';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import EditUser from '@/components/ManageUsers/EditUser/EditUser.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import { errorFilter } from '@/filters/errorMsg';

export default {
  name: 'Database',
  components: { Btn, DataTable, ConfirmationModal, Modal, EditUser, NumberTextField },
  props: {
    systems: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    ...validateRules,
    DELETE_SYSTEM_MUTATION,
    DELETE_COMPONENT_MUTATION,
    units: null,
    currentUser: null,
    editableField: '',
    valid: true,
    averageLifeCycleRange: [],
    averageLifeCycleMin: 0,
    averageLifeCycleMax: 99,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'System',
          value: 'name',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        {
          text: 'Category',
          value: 'categoryName',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        {
          text: 'FIN Code',
          value: 'finCode',
          type: 'text',
          dataType: 'string',
          width: '100px',
        },
        {
          text: 'Component',
          value: 'componentName',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        { text: 'Cost Per Unit', value: 'costPerUnit', type: 'number', dataType: 'currency', width: '100px' },
        { text: 'Unit', value: 'unitName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Average Lifecycle', value: 'averageLifeCycle', type: 'number', dataType: 'string', width: '100px' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '43px',
          fixed: true,
          permissions: [PERMISSIONS.DELETE_DATABASE_COMPONENT, PERMISSIONS.DELETE_SYSTEM],
        },
      ];
    },
    displayedItems() {
      return this.systems.length > 0
        ? this.systems.reduce((items, system) => {
            const components =
              system.components !== null && system.components.length > 0
                ? system.components.map(component => {
                    return {
                      ...system,
                      id: system.id,
                      index: system.id + '_' + component.id,
                      name: system.name,
                      categoryName: system.category.name,
                      componentName: component.name,
                      componentId: component.id,
                      costPerUnit: component.costPerUnit,
                      unitName: component.unit.name,
                      unitId: component.unit.id,
                      averageLifeCycle: component.averageLifeCycle,
                    };
                  })
                : [
                    {
                      id: system.id,
                      index: system.id,
                      name: system.name,
                      finCode: null,
                      categoryName: null,
                      componentName: null,
                      componentId: null,
                      costPerUnit: null,
                      unitName: null,
                      unitId: null,
                      averageLifeCycle: null,
                    },
                  ];
            items.push(...components);
            return items;
          }, [])
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_DATABASE_COMPONENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_DATABASE_COMPONENT, this.currentUser);
    },
    showDeleteSystemBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_SYSTEM, this.currentUser);
    },
  },
  methods: {
    clearAverageLifeCycle(item) {
      item.averageLifeCycle = '';
      this.averageLifeCycleRange = [];
      this.$refs.database_table.$el.click();
    },
    populateAverageLifeCycle(range) {
      const averageLifeCycleRange = [1, 5];
      if (range) {
        if (range.length > 2) {
          range = range.split('-');
          averageLifeCycleRange[0] = parseInt(range[0]);
          averageLifeCycleRange[1] = parseInt(range[1]);
        } else {
          averageLifeCycleRange[0] = averageLifeCycleRange[1] = parseInt(range);
        }
      }
      return averageLifeCycleRange;
    },
    onAverageLifeCycleOpen(item) {
      setTimeout(() => {
        this.averageLifeCycleRange = this.populateAverageLifeCycle(item.averageLifeCycle);
      }, 100);
    },
    onCostOpen(item) {
      setTimeout(() => {
        this.editableField = item.costPerUnit;
      }, 100);
    },
    onComponentOpen(item) {
      setTimeout(() => {
        this.editableField = item.componentName;
      }, 100);
    },
    saveAverageLifeCycle(item) {
      let range = '';
      if (this.averageLifeCycleRange.length) {
        if (this.averageLifeCycleRange[0] === this.averageLifeCycleRange[1]) {
          range = this.averageLifeCycleRange[0].toString() || '';
        } else {
          range = `${this.averageLifeCycleRange[0]} - ${this.averageLifeCycleRange[1]}`;
        }
      }
      item.averageLifeCycle = JSON.parse(JSON.stringify(range));
      this.saveComponent(item);
    },
    saveCost(item) {
      item.costPerUnit = this.editableField;
      if (!this.valid) return;
      this.$emit('cost-per-unit-change');
      this.saveComponent(item);
    },
    saveComponentName(item) {
      this.$refs[item.id + item.index].cancel();
      item.componentName = this.editableField;
      this.saveComponent(item);
    },
    saveComponent(item) {
      if (!this.valid) return;
      this.$apollo
        .mutate({
          // Mutation
          mutation: UPDATE_COMPONENT_MUTATION,
          // Parameters
          variables: {
            input: {
              id: item.componentId,
              name: item.componentName,
              costPerUnit: item.costPerUnit,
              unitId: item.unitId,
              averageLifeCycle: item.averageLifeCycle,
            },
          },
        })
        .then(() => {
          eventHub.$emit('show-snackbar', { color: 'success', text: `Component ${item.componentName} is updated!` });
        })
        .catch(e => {
          eventHub.$emit('show-snackbar', { color: 'error', text: errorFilter(e.message) });
        });
      return false;
    },
    onSystemDelete(item) {
      return (store, { data: { deleteSystem } }) => {
        if (deleteSystem) {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: SYSTEMS_QUERY,
          });
          data.systems = data.systems.filter(system => {
            return system.id !== item.id;
          });
          store.writeQuery({
            query: SYSTEMS_QUERY,
            data,
          });
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'System was deleted successfully!',
          });
        }
      };
    },
    onComponentDelete(item) {
      return (store, { data: { deleteComponent } }) => {
        if (deleteComponent) {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: SYSTEMS_QUERY,
          });
          const index = data.systems.findIndex(system => {
            return system.id === item.id;
          });
          if (index >= 0) {
            data.systems[index].components = data.systems[index].components.filter(component => {
              return component.id !== item.componentId;
            });
            store.writeQuery({
              query: SYSTEMS_QUERY,
              data,
            });
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Component was deleted from the system successfully!',
          });
        }
      };
    },
  },
};
