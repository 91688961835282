import ADD_COMPONENT_MUTATION from '@/graphql/mutations/addComponent.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Btn from '@/components/Btn/Btn.vue';
import eventHub from '@/utils/eventHub';
import validateRules from '@/services/validateRules';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';

export default {
  name: 'AddComponent',
  components: { Btn, ExpansionPanelHeader },
  apollo: {
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load unit list.' }
          : error;
      },
    },
  },
  props: {
    systems: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    ADD_COMPONENT_MUTATION,
    component: { system: null, name: '', costPerUnit: 1, unit: null, averageLifeCycle: '' },
    nameRules: [v => !!v || 'Name is Required'],
    costRules: [
      v => (v !== null && v !== '') || 'Cost is Required',
      validateRules.positiveNumber,
      validateRules.floatNumber(2),
    ],
    unitRules: [v => !!v || 'Unit is Required'],
    units: [],
    systemRules: [v => !!v || 'System is Required'],
    error: null,
    averageLifeCycleMin: 0,
    averageLifeCycleMax: 99,
    averageLifeCycleRange: [1, 5],
    isCloseAverageLifeCycle: false,
    averageLifeCyclePanel: undefined,
  }),
  computed: {
    displayedRoles() {
      const canAddAdminUser = accessByPermissions(PERMISSIONS.ADD_ADMIN, this.currentUser);
      return canAddAdminUser ? this.roles : this.roles.filter(r => r.name !== 'admin');
    },
    averageLifeCycleString() {
      if (this.averageLifeCyclePanel === undefined) {
        return '';
      }
      let result = '';
      if (this.averageLifeCycleRange[0] === this.averageLifeCycleRange[1]) {
        result = this.averageLifeCycleRange[0].toString() || '';
      } else {
        result = `${this.averageLifeCycleRange[0]} - ${this.averageLifeCycleRange[1]}`;
      }
      return result;
    },
  },
  methods: {
    clearAverageLifeCycle() {
      this.averageLifeCyclePanel = undefined;
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit(mutate) {
      if (this.validate()) {
        this.component.averageLifeCycle = this.averageLifeCycleString;
        this.$nextTick(() => {
          mutate();
        });
      }
    },
    updateSystems(store, { data: { addComponent } }) {
      if (addComponent) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: SYSTEMS_QUERY,
        });
        const index = data.systems.findIndex(s => s.id === addComponent.system.id);
        if (index !== -1) {
          if (!data.systems[index].components) {
            data.systems[index].components = [];
          }
          data.systems[index].components.unshift(addComponent);
          store.writeQuery({
            query: SYSTEMS_QUERY,
            data,
          });
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Component ${this.component.name} was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
