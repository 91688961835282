import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import Btn from '@/components/Btn/Btn.vue';
import Database from '@/components/ManageDatabase/Database/Database.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddSystem from '@/components/ManageDatabase/AddSystem/AddSystem.vue';
import AddComponent from '@/components/ManageDatabase/AddComponent/AddComponent.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import { sortByName } from '@/utils/sortByName';

export default {
  name: 'ManageDatabaseSystems',
  components: { AddSystem, AddComponent, Modal, Btn, Database },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    systems: {
      query: SYSTEMS_QUERY,
      fetchPolicy: 'cache-and-network',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load systems components.' } : error;
      },
      update(data) {
        return data.systems.sort(sortByName);
      },
    },
  },
  data: () => ({
    currentUser: null,
    error: null,
  }),
  computed: {
    systemsLoading() {
      return this.$apollo.queries.systems.loading;
    },
    showAddSystemBtn() {
      return accessByPermissions(PERMISSIONS.CREATE_SYSTEM, this.currentUser);
    },
    showAddComponentBtn() {
      return accessByPermissions(PERMISSIONS.CREATE_DATABASE_COMPONENT, this.currentUser);
    },
  },
};
